<template>
  <div class="earth-model">
    <div
      ref="container"
      class="earth-wrapper"
      :class="{ visible: !state.loading, light: lightMode }"
      @mouseover="state.userIsHovering = true"
      @mouseout="state.userIsHovering = false"
    ></div>
    <div class="glow"></div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, reactive } from 'vue'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const props = defineProps<{
  lightMode?: boolean
}>()

const state = reactive({
  loading: true,
  userIsHovering: false,
})

const emit = defineEmits(['route'])

let scene: THREE.Scene
let camera: THREE.PerspectiveCamera
let renderer: THREE.WebGLRenderer
let sphere: THREE.Mesh
let controls: OrbitControls

let userIsInteracting = false
let icons: THREE.Mesh[] = []
const container = ref<HTMLElement | null>(null)
let bounds = container.value?.getBoundingClientRect()

const textureMapPath = '/img/earth/hologram-map-light.svg'
const logoPath = '/img/earth/logo.svg'
const links = reactive([
  {
    id: 'britain',
    query: 'britain',
    x: 0,
    y: 52,
  },
  {
    id: 'germany',
    query: 'germany',
    main: true,
    x: -7,
    y: 47,
  },
  {
    id: 'france',
    query: 'france',
    x: 50,
    y: 40,
  },
  {
    id: 'test2',
    query: 'test2',
    x: 100,
    y: 170,
  },
])

onMounted(() => {
  init()
})

function init() {
  scene = new THREE.Scene()
  camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000)
  camera.position.z = 2

  renderer = new THREE.WebGLRenderer({ alpha: true })

  bounds = container.value?.getBoundingClientRect()

  if (process.client) {
    renderer.setSize(bounds?.width || 0, bounds?.height || 0)
  }

  container.value?.appendChild(renderer.domElement)

  // load the svg map texture
  const textureLoader = new THREE.TextureLoader()
  textureLoader.load(textureMapPath, function (texture) {
    const sphereGeometry = new THREE.SphereGeometry(1, 256, 256)
    const sphereMaterial = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: props.lightMode ? 0.8 : 0.5,
      color: 0xffffff,
      // blending: THREE.MultiplyBlending,
      side: THREE.DoubleSide,
    })
    sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)
    sphere.rotation.x = Math.PI / 4

    scene.add(sphere)

    // add location markers
    links.forEach((el) => {
      textureLoader.load(logoPath, (texture) => {
        const geometry = new THREE.PlaneGeometry(0.04, 0.04)
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
          color: el.main ? 0xbb2649 : props.lightMode ? 0xffffff : 0x000000,
          side: THREE.DoubleSide,
        })

        const icon = new THREE.Mesh(geometry, material)
        const radius = 1.02 // is a bit above the sphere surface

        const theta = THREE.MathUtils.degToRad(el.x) // longitude
        const phi = THREE.MathUtils.degToRad(90 - el.y) // latitude
        icon.position.set(
          radius * Math.sin(phi) * Math.cos(theta),
          radius * Math.cos(phi),
          radius * Math.sin(phi) * Math.sin(theta)
        )

        // adjust icon to the middle of the sphere
        icon.lookAt(sphere.position)

        icons.push(icon)

        sphere.add(icon)
      })
    })

    // show sphere
    state.loading = false
    // wait until now for animation requests
    animate()
  })

  // set the navigation/controls
  controls = new OrbitControls(camera, renderer.domElement)
  controls.minDistance = 1.5
  controls.maxDistance = 3
  controls.minPolarAngle = 0
  controls.maxPolarAngle = Math.PI / 2 // 90 degrees

  // check, if user is interacting with the sphere
  controls.addEventListener('start', () => {
    userIsInteracting = true
  })

  controls.addEventListener('end', () => {
    userIsInteracting = false
  })

  addClickEvents()
}

// start the auto animation
function animate() {
  if (!sphere) {
    return
  }
  requestAnimationFrame(animate)

  if (!userIsInteracting && !state.userIsHovering) {
    sphere.rotation.y += 0.003 // sphere rotation speed
  }
  renderer.render(scene, camera)

  icons.forEach((el) => {
    el.lookAt(camera.position)
  })
}

function addClickEvents() {
  if (process.client) {
    // click event for the markers
    container.value?.addEventListener('click', (event) => {
      const raycaster = new THREE.Raycaster()
      const mouse = new THREE.Vector2()

      mouse.x = (event.offsetX / (bounds?.width || 0)) * 2 - 1
      mouse.y = -(event.offsetY / (bounds?.height || 0)) * 2 + 1
      if (camera) {
        raycaster.setFromCamera(mouse, camera)
      }

      const intersects = raycaster.intersectObjects(icons, true)

      for (let i = 0; i < intersects.length; i++) {
        const intersectedObject = intersects[i].object as THREE.Mesh
        const index = icons.indexOf(intersectedObject)

        if (index !== -1 && links[index]) {
          emit('route', links[index]?.query)
        }
      }
    })
  }
}
</script>

<style lang="scss">
.earth-model {
  position: relative;
}
.earth-wrapper {
  transition: opacity 1s 1s;
  opacity: 0;
  aspect-ratio: 1;
  &.visible {
    opacity: 1;
  }
  &.light {
    //.earth-logo-overlay {
    //  color: $color-primary;
    //}
  }
  canvas {
    width: 100% !important;
    height: auto !important;
    z-index: 0;
  }
}
/* background glow */
.glow {
  pointer-events: none;
  mix-blend-mode: plus-lighter;
  opacity: 0.5;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  min-width: 100%;
  max-width: 100vh;
  height: auto;
  min-height: 100%;
  max-height: 100vw;
  top: 50%;
  left: 50%;
  z-index: 200;

  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.05) 25%,
    rgba(255, 255, 255, 0.15) 53%,
    rgba(255, 255, 255, 0.05) 56%,
    rgba(255, 255, 255, 0) 70%
  );
  &.light {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.3) 25%,
      rgba(255, 255, 255, 0.75) 53%,
      rgba(255, 255, 255, 0.3) 56%,
      rgba(255, 255, 255, 0) 70%
    );
  }
}

//.earth-logo-overlay {
//  width: 2em;
//  height: 2em;
//  color: $color-inverted;
//}
</style>
