<template>
  <section class="earth-box" :class="{ dark }">
    <div class="content standard-padding">
      <div class="title">
        <aside v-if="aside">{{ aside }}</aside>
        <h1 v-html="title"></h1>
        <nuxt-link v-if="button" :to="localePath({ name: 'contact' })">
          <AppButton red>Ansprechpartner finden</AppButton>
        </nuxt-link>
      </div>
    </div>
    <EarthModel @route="routeChange" :light-mode="dark" />

    <div class="hint">
      <GlobeNavigation />
      <!--@todo: translate-->
      <aside>Maustaste gedrückt halten <br />und Weltkugel drehen …</aside>
    </div>
  </section>
</template>

<script lang="ts" setup>
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()

const props = defineProps<{
  title: string
  aside?: string
  dark?: boolean
  button?: boolean
}>()

function routeChange(query: string) {
  // set the country query and – if needed – navigate to contact page
  navigateTo({
    path: localePath({ name: 'contact' }),
    query: { country: query },
  })
}
</script>

<style lang="scss" scoped>
.earth-box {
  overflow: hidden;
  position: relative;
  padding-bottom: 5em;
  font-size: $font-18;
  @media (min-width: $bp-md) {
    display: flex;
    align-items: center;
  }
}

.dark {
  background: $color-black;
  .content,
  .hint {
    color: $color-inverted;
  }
}

.content {
  color: $color-primary;
  .title {
    aside {
      margin-bottom: 1.25em;
      font-size: max(1.2rem, 1.25vw); // 18
      //font-weight: $weight-bold;
      color: $color-primary;
    }
    h1 {
      margin-bottom: 0.75em;
      font-weight: $weight-normal;
      white-space: pre-wrap;
    }
  }
  @media (min-width: $bp-md) {
    order: 1;
    width: $col-span-md * 4 + $offset-md;
    //width: 50%;
    margin-bottom: 3em;
    h1 {
      width: 12ch;
    }
  }
}
.earth-model {
  @media (min-width: $bp-md) {
    order: 1;
    width: $col-span-md * 7;
  }
}

.hint {
  display: flex;
  align-items: center;
  //padding: $standard-padding $standard-padding $standard-padding * 2;
  padding-inline: $offset;
  font-size: $font-12;
  color: $color-primary;
  .globe-navigation {
    width: 4.75em;
    margin-right: 2em;
  }
  @media (min-width: $bp-md) {
    position: absolute;
    padding-inline: unset;
    bottom: $offset-md;
    left: $offset-md + $column-md;
    //bottom: $standard-padding-md;
    //left: calc(50% + #{$standard-padding-md});
  }
}
</style>
