<template>
  <svg
    class="globe-navigation"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 62 62"
  >
    <g class="globes">
      <path
        class="globe1"
        d="M17.5 31c0-7.5 6-13.5 13.5-13.5s13.5 6 13.5 13.5-6 13.5-13.5 13.5-13.5-6-13.5-13.5zm12.7-11.7c-1.1.3-2.3 1.4-3.2 3.1-.3.6-.6 1.2-.8 1.8h4v-4.9zm-5.8 4.9c.3-.9.6-1.8 1.1-2.6.3-.6.6-1.1 1-1.6-2.1.9-3.9 2.3-5.2 4.2h3.1zm-1 6c.1-1.5.2-2.9.5-4.2h-3.6c-.6 1.3-1 2.8-1.1 4.2h4.2zm2.3-4.3c-.3 1.4-.5 2.8-.6 4.2h5v-4.2h-4.4zm6.1 0v4.2h5c0-1.4-.2-2.8-.6-4.2h-4.4zm-6.7 5.9c0 1.4.2 2.8.6 4.2h4.5v-4.2h-5.1zm6.7 0V36h4.5c.3-1.3.5-2.7.6-4.2h-5.1zm-5.6 6c.2.7.5 1.3.8 1.8.9 1.7 2.1 2.8 3.2 3.1v-4.9h-4zm.3 4.1c-.4-.5-.7-1-1-1.6-.4-.8-.8-1.7-1.1-2.6h-3.1c1.3 1.9 3.1 3.4 5.2 4.2zm-2.6-5.8c-.3-1.4-.5-2.8-.5-4.2h-4.2c.1 1.5.5 2.9 1.1 4.2h3.6zm11.6 5.8c2.1-.9 3.9-2.3 5.2-4.2h-3.1c-.3.9-.6 1.8-1.1 2.6-.3.6-.6 1.1-1 1.6zm-3.7-4.1v4.9c1.1-.3 2.3-1.4 3.2-3.1.3-.5.6-1.2.8-1.8h-4zm6.3-1.7h3.6c.6-1.3 1-2.7 1.1-4.2h-4.2c-.1 1.4-.2 2.8-.5 4.2zm4.7-5.9c-.1-1.5-.5-2.9-1.1-4.2h-3.6c.3 1.3.5 2.7.5 4.2h4.2zm-6.3-8.5c.4.8.8 1.7 1.1 2.6h3.1c-1.3-1.9-3.1-3.3-5.2-4.2.4.5.7 1 1 1.6zm-.7 2.5c-.2-.6-.5-1.2-.8-1.8-.9-1.7-2.1-2.8-3.2-3.1v4.9h4z"
      />
      <path
        class="globe2"
        d="M31 17.5c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5 13.5-6 13.5-13.5-6-13.5-13.5-13.5zm11.8 12.7h-2.2c-.1-1.5-.2-2.9-.5-4.2h1.6c.6 1.3 1 2.7 1.1 4.2zm-2.1-6h-1.1c-.2-.8-.5-1.5-.9-2.2.8.7 1.4 1.4 2 2.2zm-1.8 6h-3c-.1-1.3-.3-2.8-.5-4.2h3c.3 1.3.4 2.7.5 4.2zm-11.2 0c.1-1.3.3-2.8.5-4.2h5.4c.2 1.4.4 2.9.5 4.2h-6.4zm6.5 1.6c0 1.3-.2 2.8-.4 4.2h-5.6c-.2-1.5-.4-2.9-.4-4.2h6.4zm2.8-9.4c.3.6.6 1.2.8 1.8H35c-.3-1.6-.7-3.1-.9-4.1 1.2.6 2.4 1.4 2.9 2.3zm-6-3.2c.4 0 .8.1.8.1.1 0 .2.1.4.1.2.9.7 2.7 1.1 4.8h-4.8c.4-2.1.9-3.9 1.1-4.8.2-.1.3-.1.5-.1.1 0 .5-.1.9-.1zm-6 3.2c.5-1 1.7-1.7 2.8-2.2-.3 1-.6 2.4-.9 4h-2.7c.2-.6.5-1.2.8-1.8zm1.6 3.5c-.2 1.4-.4 2.9-.5 4.2h-2.9c0-1.4.2-2.8.6-4.2h2.8zm-3.3-3.8c-.3.7-.6 1.4-.9 2.2h-1.1c.6-.9 1.2-1.6 2-2.2zm-3 3.8h1.6c-.3 1.3-.5 2.7-.5 4.2h-2.2c.1-1.4.5-2.8 1.1-4.2zm-1.1 5.9h2.2c0 1.4.2 2.8.5 4.2h-1.6c-.6-1.2-1-2.7-1.1-4.2zm2.1 6h1.1c.2.7.5 1.5.9 2.2-.8-.7-1.4-1.4-2-2.2zm1.8-6H26c0 1.3.2 2.8.4 4.2h-2.8c-.3-1.3-.4-2.7-.5-4.2zm1.9 7.8c-.3-.5-.6-1.2-.8-1.8h2.6c.3 1.5.6 2.9.9 4-1.2-.6-2.2-1.3-2.7-2.2zm6 3.2c-.4 0-.8-.1-.8-.1-.2-.1-.4-.1-.6-.2-.3-1.1-.7-2.8-1.1-4.7h5c-.4 1.9-.8 3.6-1.1 4.7-.2.1-.4.1-.6.2 0 0-.4.1-.8.1zm6-3.2c-.5.9-1.6 1.7-2.7 2.2.3-1.1.6-2.5.9-4h2.7l-.9 1.8zm-1.5-3.5c.2-1.5.4-2.9.4-4.2h3c-.1 1.5-.3 2.9-.6 4.2h-2.8zm3.2 3.8c.3-.7.6-1.4.9-2.2h1.1c-.6.9-1.2 1.6-2 2.2zm3-3.8h-1.6c.3-1.4.5-2.8.5-4.2h2.2c-.1 1.4-.5 2.9-1.1 4.2z"
      />
    </g>
    <path
      class="l"
      d="M9.4 24.6c.1.1.1.2.1.4 0 .1-.1.3-.1.4L3.7 31l5.6 5.6c.1.1.2.3.1.5 0 .2-.2.3-.4.4s-.4 0-.5-.1l-6-6c-.1-.1-.1-.2-.1-.4 0-.1.1-.3.1-.4l6-6c.1-.1.2-.1.4-.1s.4.1.5.1z"
    />
    <path
      class="b"
      d="M37.4 52.6c-.1-.1-.2-.1-.4-.1s-.3.1-.4.1L31 58.3l-5.6-5.6c-.1-.1-.3-.2-.5-.1-.2 0-.3.2-.4.4 0 .2 0 .4.1.5l6 6c.1.1.2.1.4.1.1 0 .3-.1.4-.1l6-6c.1-.1.1-.2.1-.4s-.1-.4-.1-.5z"
    />
    <path
      class="r"
      d="M52.6 24.6c-.1.1-.1.2-.1.4 0 .1.1.3.1.4l5.6 5.6-5.6 5.6c-.1.1-.2.3-.1.5 0 .2.2.3.4.4.2 0 .4 0 .5-.1l6-6c.1-.1.1-.2.1-.4 0-.1-.1-.3-.1-.4l-6-6c-.1-.1-.2-.1-.4-.1s-.3.1-.4.1z"
    />
    <path
      class="t"
      d="M37.4 9.4c-.1.1-.2.1-.4.1s-.3-.1-.4-.1L31 3.7l-5.6 5.6c-.1.1-.3.2-.5.1-.2 0-.3-.2-.4-.4s0-.4.1-.5l6-6c.1-.1.2-.1.4-.1.1 0 .3.1.4.1l6 6c.1.1.1.2.1.4s-.1.4-.1.5z"
    />
  </svg>
</template>
<script lang="ts" setup></script>
<style lang="scss" scoped>
@keyframes globeAnimation {
  0% {
    transform: translateX(-62px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-62px);
  }
}
.globe-navigation {
  @media (hover: hover) {
    &:hover {
      .t {
        transform: translateY(-2px);
      }
      .r {
        transform: translateX(2px);
      }
      .b {
        transform: translateY(2px);
      }
      .l {
        transform: translateX(-2px);
      }
      .globes {
        animation: globeAnimation 0.3s infinite step-end;
      }
    }
  }
}
.globe2 {
  transform: translateX(62px);
}
path {
  transition: transform 0.33s, fill 0.33s;
  fill: currentColor;
}
</style>
